<template>
    <div>
        <div class="patientDetailParentContainer" v-if="propsRecivedFlag">
            <h5>Patient Details</h5>
            <p style="color: var(--grays-grey-3, #828282);">Patient Name?</p>
            <div class="selectPatientContainer">
                <v-select :items="patientDetails" v-model="patientName" class="select" outlined attach @change="patientTypeHandler()"></v-select>
                <v-btn plain style="height: 3.6rem; background: #E4F5FF; border-radius: 8px; margin-left: 10px;" @click="addDependentClicked()">
                    <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/pluseIcon.svg" alt="Add">
                </v-btn>
            </div>
            <p style="color: var(--grays-grey-3, #828282);">Description (Optional)</p>
            <v-textarea outlined style="width: 100%;" v-model="description" rows="4" row-height="25" no-resize></v-textarea>
            <div style="display: flex; justify-content: space-between; align-items: center; align-self: stretch;">
                <P style="color: var(--grays-grey-3, #828282);">Select Address</P>
                <p style="color: #48ACEF; cursor: pointer;" @click="addNewAddressClicked()">Add New</p>
            </div>
            <div class="addressListingDiv" v-for="addressDetail in $props.addressList" :key="addressDetail.address_unique_id">
                <v-card style="cursor: pointer;" flat :style="{ border: (addressDetail.address_unique_id===selectedAddressIndialog.address_unique_id) ? '2px solid #48ACEF' : 'none' }">
                    <div class="addressCardStyle">
                        <label :style="{ color: (addressDetail.address_unique_id===selectedAddressIndialog.address_unique_id) ? '#48ACEF' : 'black' }">
                            <input 
                                :disabled="addressDetail.avail_status=='UNAVAILABLE'"
                                @click="addressSelectionHandler(addressDetail)" type="radio" color="#48ACEF" :value="addressDetail" 
                                v-model="selectedAddressIndialog" :id="addressDetail.address_unique_id" name="addressSelection">
                            {{ addressDetail.address_type_name }}
                        </label>
                        <div style="padding-left: 2rem; color:#828282; font-size: 12px; font-weight: 500; padding-top: 8px; text-align: left; padding-left: 1rem;">
                            <p>{{ `${addressDetail.delivered_to_name},` }}</p>
                            <p>{{ `${addressDetail.street_address}, ${addressDetail.state_id}` }}</p>
                            <p>{{ addressDetail.township }}</p>
                        </div>
                        <div class="dividerStyle"></div>
                        <div v-if="addressDetail.avail_status=='AVAILABLE'" class="addressCardActions">
                            <div>
                                <v-btn 
                                    :style="{ color: (addressDetail.address_unique_id===selectedAddressIndialog.address_unique_id) ? '#48ACEF' : 'black' }" 
                                    text style="text-transform: capitalize; padding: 0px !important;" @click="editAddressClicked(addressDetail.address_unique_id)">
                                    <img v-if="addressDetail.address_unique_id===selectedAddressIndialog.address_unique_id" src="https://s3iconimages.mymedicine.com.mm/editBtnAddress.svg" style="margin-right: 5px;">
                                    <img v-else src="https://s3iconimages.mymedicine.com.mm/edit_icon_black.svg" style="margin-right: 5px;">
                                    Edit
                                </v-btn>
                            </div>
                        </div>
                        <div v-else class="addressCardButtonStyle">
                            <div class="addressNotServicableDiv">
                                <img src="https://s3iconimages.mymedicine.com.mm/nurseUnavailableRedEmoji.svg" height="20px" width="20px" style="margin-right: 5px;">
                                <p class="ma-0 pa-0 font-weight-medium light-red-color-text body-heading">Not servicable at the moment</p>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
            <div v-if="addressDetails==null || addressDetails.length==0" class="addressListingDiv d-flex justify-center">
                <v-card flat class="noAddressCard">
                    <p>No Address Found</p>
                </v-card>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'paitentDetailsComponent',
    props: ['addressList', 'dependentList','customerMedicalInfo'],
    data() {
        return {
            patientDetails: ['myself'],
            addressDetails: [],
            dependetDetails: [],
            patientName: '',
            selectAddressDialogFlag: false,
            propsRecivedFlag: true,
            selectedAddressIndialog: {},
            selectedAddress: {},
            customerMedicalDetails:{},
            selectedPatientInfo:{},
            description:''
        }
    },
    methods: {
        addDependentClicked() {
            this.$router.push({
                path: '/nurseBooking/addDependent',
            });
        },
        addNewAddressClicked() {
            this.$router.push({
                name: 'NBACustomerAddAddress'
            });
        },
        editAddressClicked(address_id) {
            this.$router.push({
                path: '/nurseBooking/editAddress/' + address_id,
            });
        },
        addressSelectionHandler(addressDetail){
            this.selectedAddress = addressDetail;
            this.selectedAddressIndialog = addressDetail;
            this.selectAddressDialogFlag = false;
        },
        patientTypeHandler(){
            if(this.patientName === 'myself'){
                this.selectedPatientInfo = this.customerMedicalDetails
                this.$store.dispatch('changePatientDetails',this.selectedPatientInfo)
                return
            }
            for(let dependetDetail of this.dependetDetails){
                if(dependetDetail.name === this.patientName){
                    this.selectedPatientInfo = dependetDetail
                    break
                }
            }
            this.$store.dispatch('changePatientDetails',this.selectedPatientInfo)
        },
    },
    watch:{
        selectedAddress(newValue){
            this.$store.dispatch('changeAddressDetails',newValue)
        },
        description(newValue){
            this.$store.dispatch('changeNurseBookingdescription',newValue)
        }
    },
    mounted() {
        if (this.$props.addressList === undefined || this.$props.dependentList === undefined) {
            this.propsRecivedFlag = true
            return
        }
        this.addressDetails = this.$props.addressList
        this.dependetDetails = this.$props.dependentList
        this.customerMedicalDetails = this.$props.customerMedicalInfo
        this.patientDetails = []
        this.patientDetails.push('myself')
        for (let dependent of this.dependetDetails) {
            this.patientDetails.push(dependent.name)
        }
        this.selectedAddressIndialog = this.$store.state.nurseBooking.addressDetails
        this.selectedAddress = this.$store.state.nurseBooking.addressDetails
        this.description = this.$store.state.nurseBooking.description
        let patientName = this.$store.state.nurseBooking.patientDetails
        if(Object.keys(patientName).includes('dependent_customer_relationship')){
            this.patientName = patientName.name
        }else{
            this.patientName = 'myself'
            this.selectedPatientInfo = this.customerMedicalDetails
            this.$store.dispatch('changePatientDetails',this.selectedPatientInfo)
        }
    }
}
</script>

<style scoped>
@import '../../../src/scss/classes.scss';
@import '../../../src/scss/global.scss';
.noAddressCard {
    background-color: #F4F6FA;
    color: #8A8A8A;
    height: 50px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addressCardActions {
    width: 100%;
    display: flex !important;
    justify-content: flex-end !important;
}
.addressNotServicableDiv {
    padding: 10px;
    background-color: #FFE9E9;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
}
.addressListingDiv {
    width: 100%
}
.patientDetailParentContainer {
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding: 14px;
    width: 100%;
}

.selectPatientContainer {
    display: flex;
    width: 100%;
    height: 45px;
    margin-bottom: 12px;
}

.patientDetailParentContainer textarea {
    width: 100%;
    height: 100%;
}

.addressDetailsContainer {
    display: flex;
    flex-direction: row;
    border: solid #E0E0E0 1px !important;
    width: 100%;
    color: #828282 !important;
    padding: 12px 14px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.addressDetailsContainer div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.addressCardStyle {
    display: flex;
    padding: 10px;
    flex-direction: column;
    width: 100% !important;
    align-items: flex-start;
    /* align-self: stretch; */
    width: 100% !important;
    border-radius: 8px;
    border: 1px solid #E0E0E0 !important;
    background: #FFF;
    font-weight: 600 !important;
}

.addressCardButtonStyle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}

.dividerStyle {
    width: 100%;
    height: 1px;
    background: #F2F2F2 !important;
    margin-top: 10px;
}

P{
    margin: 0;
}
.v-input {
    height: 140px;
    margin-bottom: 20px;
}
</style>
